import React from "react";
import styled from "styled-components";
import flexbox from "../styles/function/flexbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partnerMockUp from "../data/partnerMockUp";

console.log(partnerMockUp)

function DetailMockUpPartnerSection() {
  return (
    <Section>
      <PhoneWrapper>
        <Wrap mobile={true}>
          <Slider
            cssEase="linear"
            arrows={false}
            dots={false}
            infinite={true}
            slidesToShow={1}
            centerMode={true}
            centerPadding="100px"
            pauseOnHover={false}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={4000}
          >
            {partnerMockUp.map((item, idx) => (
              <MockImageCenter key={item.id} src={item.img} />
            ))}
          </Slider>
        </Wrap>
        <Wrap pc={true}>
          <Slider
            cssEase="linear"
            arrows={false}
            dots={false}
            infinite={true}
            slidesToShow={3}
            pauseOnHover={false}
            centerPadding="0px"
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3500}
          >
            {partnerMockUp.map((item, idx) => (
              <MockImageCenter key={item.id} src={item.img} />
            ))}
          </Slider>
        </Wrap>
      </PhoneWrapper>
    </Section>
  );
}

export default DetailMockUpPartnerSection;

const Section = styled.div`
  ${flexbox("center", "center", "row")}
  position: relative;
  padding-bottom: 30px;
  @media screen and (max-width: 560px) {
    margin-bottom: 48px;
  }
`;

const PhoneWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${flexbox("flex-start", "flex-end", "row")}
  gap: 28px;
  flex-wrap: wrap;
  bottom: -30px;
  @media screen and (max-width: 560px) {
    height: auto;
    margin-bottom: 0px;
  }
`;

const MockImageCenter = styled.img`
  padding: 40px 30px 20px 30px;
  @media screen and (max-width: 560px) {
    padding: 40px 20px 20px 20px;
  }
  z-index: 0;
  filter: drop-shadow(4px -3px 5px rgba(0, 0, 0, 0.25))
    drop-shadow(-5px 8px 3px rgba(0, 0, 0, 0.25));
`;

const Wrap = styled.div`
  z-index: 0;
  background-color: transparent;
  max-width: 780px;
  width: 40vw;

  @media screen and (min-width: 561px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }
  @media screen and (max-width: 560px) {
    display: ${({ pc }) => (pc ? "none" : "block")};
  }

  .slick-slide {
    transform: scale(1.1) translate(10px, 0px);
    transition: transform 0.3s linear;
  }

  .slick-current {
    transform: scale(1.25) translate(5px, -25px);
    transition: transform 0.3s linear;
    img {
      filter: drop-shadow(2px -2px 3px rgba(0, 0, 0, 0.25))
        drop-shadow(1px 5px 3px rgba(0, 0, 0, 0.25));
    }
  }
  .slick-list {
    padding: 75px 0px 30px 0 !important;
  }

  @media screen and (min-width: 561px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }
  @media screen and (max-width: 560px) {
    display: ${({ pc }) => (pc ? "none" : "block")};
  }
  @media screen and (max-width: 1024px) {
    width: 70vw;
  }
  @media screen and (max-width: 560px) {
    width: 100vw;
    .slick-list {
      padding: 30px 0px 30px 0px !important;
    }
    img {
    }
    .slick-slide {
      transform: scale(1) translate(100px, 0px);
      transition: transform 0.3s linear;
    }
    .slick-center {
      align-self: flex-start;
      transform: scale(1) translate(100px, 0px);
      transition: transform 0.3s linear;
    }
  }
`;
