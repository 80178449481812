import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Spinner from "./components/spinner";
import Footer from "./layouts/footer";
import Gnb from "./layouts/gnb";

// pages
import NotFound from "./pages/notFound";
import HomePage from "./pages/homePage";


const CompanyIntroPage = lazy(() => import("./pages/companyIntroPage"));
const RecruitPage = lazy(() => import("./pages/RecruitPage"));

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Gnb />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/reports_intro" element={<CompanyIntroPage />} />
            <Route path="/reports_recruit" element={<RecruitPage />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
