import React from "react";
import styled from "styled-components";
import unStar from "../assets/icon/unStar.svg";
import onStar from "../assets/icon/onStar.svg";
import halfStar from "../assets/icon/halfStar.svg";
import colors from "../styles/constants/colors";
import flexbox from "../styles/function/flexbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import review from "../data/review";

function DetailReview({lang}) {
  return (
    <>
      <Section>
        <p>Reviews</p>
        <p>{lang ? 'Stories of users who have experienced SEMOS' : '세모스를 만난 유저들의 이야기'}</p>
        <Slider
          className="slider"
          cssEase="linear"
          arrows={false}
          dots={false}
          infinite={true}
          variableWidth={true}
          slidesToScroll={1}
          autoplay={true}
          speed={4000}
          autoplaySpeed={0}
          pauseOnHover={true}
        >
          {review.map((data, idx) => (
            <CardBox key={idx}>
              <ReviewImage src={data.img} />
              <BottomBox>
                <CardPerformance>
                  <ReviewWrap>
                    <StarWrap>
                      {Array.from(Array(Math.floor(data.star)), (_, i) => (
                        <Icon key={i} src={onStar} />
                      ))}
                      {data.star % 1 === 0.5 &&
                        Array.from(Array(1), (_, i) => (
                          <Icon key={i} src={halfStar} />
                        ))}

                      {Array.from(Array(Math.floor(5 - data.star)), (_, i) => (
                        <Icon key={i} src={unStar} />
                      ))}
                    </StarWrap>
                    <RightWrap>
                      <Reviewer color={colors.gray05}>
                        {data.nickname} {lang ? '`s' : '님'}
                      </Reviewer>
                      <Reviewer color={colors.gray03}>
                        &nbsp;{lang ? 'vivid review' : '의 생생한 리뷰'}
                      </Reviewer>
                    </RightWrap>
                  </ReviewWrap>
                  <Review
                    letterSpacing="0"
                    color={colors.gray04}
                    fontSize="14px"
                  >
                    {data.review}
                  </Review>
                </CardPerformance>
                <div>
                  <Item>{data.item}</Item>
                </div>
              </BottomBox>
            </CardBox>
          ))}
        </Slider>
      </Section>
    </>
  );
}

export default DetailReview;

const Section = styled.div`
  margin: 120px 0 130px 0;
  @media screen and (max-width: 560px) {
    margin-top: 53px;
    height: auto;
  }
  p {
    :nth-child(1) {
      font-weight: 700;
      font-size: 16px;
      line-height: 142%;
      color: ${colors.blue07};
      margin-bottom: 20px;
      @media screen and (max-width: 560px) {
        font-size: 14px;
      }
    }
    :nth-child(2) {
      font-weight: 700;
      font-size: 53px;
      line-height: 132.5%;
      color: ${colors.black};
      margin-bottom: 102px;
      @media screen and (max-width: 560px) {
        font-size: 24px;
        margin-bottom: 45px;
      }
    }
  }
  color: black;
  text-align: center;

  @media screen and (max-width: 335px) {
    margin: 120px 0 0 0;
    .slick-slide {
      height: 200px;
    }
  }
`;
const ReviewImage = styled.img`
  width: 309px;
  height: 271px;
  border: 1.6px solid #dcdcdc;
  border-radius: 16px 16px 0 0;
  position: absolute;
  object-fit: cover;
  top: 0;
  z-index: -1;
  @media screen and (max-width: 560px) {
    border: none;
    height: 159px;
    width: 301px;
  }
`;
const CardPerformance = styled.div`
  width: 100%;
  text-align: start;
`;

const CardBox = styled.div`
  width: 310px;
  height: 441px;
  position: relative;
  z-index: 0;
  padding-top: 221px;
  margin-left: 35px;
  @media screen and (max-width: 560px) {
    height: 251px;
    width: 301px;
    padding-top: 126px;
  }
`;

const BottomBox = styled.div`
  z-index: 99;
  height: 217px;
  width: 309px;
  padding: 24px 20px 16px 25px;
  ${flexbox("space-between", "flex-end", "column")}
  border: 1.6px solid #dcdcdc;
  border-radius: 40px 0 16px 16px;
  background-color: ${colors.white};
  @media screen and (max-width: 560px) {
    background-color: ${colors.blue01};
    gap: 10px;
    padding: 12px 7px 12px 25px;
    border: none;
    height: 125px;
    width: 301px;
  }
`;

const ReviewText = styled.span`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  font-weight: 600;
  color: ${({ color }) => (color ? color : colors.gray03)};
  line-height: 142%;

  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : "-0.02em"};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media screen and (max-width: 560px) {
    -webkit-line-clamp: 3;
  }
`;

const ReviewWrap = styled.div`
  ${flexbox("space-between", "center", "row")}
  margin-bottom: 12px;
  @media screen and (max-width: 560px) {
    margin-bottom: 6px;
  }
`;

const Icon = styled.img`
  width: 13.33px;
  height: 12.77px;
`;

const StarWrap = styled.div`
  gap: 4.67px;
  display: flex;
`;

const RightWrap = styled.div`
  ${flexbox("flex-end", "center", "row")}
`;

const Reviewer = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: ${({ color }) => (color ? color : colors.gray03)};
  line-height: 142%;
  letter-spacing: -0.02em;
`;
const Review = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.gray04};
  @media screen and (max-width: 560px) {
    -webkit-line-clamp: 3;
    font-size: 12px;
    letter-spacing: -0.02em;
  }
`;
const Item = styled.span`
  font-weight: 600;
  line-height: 132%;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: ${colors.gray03};

  @media screen and (max-width: 560px) {
    font-size: 10px;
  }
`;
