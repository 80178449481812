import React, { useState } from "react";
import styled from "styled-components";

// components
import colors from "../styles/constants/colors";
import AOS from "aos";
import "aos/dist/aos.css";
import RoundButton from "./elements/RoundButton";
import RoundButtonBig from "./elements/RoundButtonBig";

// image
import AppStore from "../assets/icon/appStore.png";
import AppStoreW from "../assets/icon/appStore-w.png";
import GoogleStore from "../assets/icon/googleStore.png";
import GoogleStoreW from "../assets/icon/googleStore-w.png";

function DetailTextSectionB2C({ detail, bg, blue, lang }) {

  const [RightMouse, setRightMouse] = useState(false);
  const [LeftMouse, setLeftMouse] = useState(false);

  AOS.init({
    duration: 1000,
    once: false,
    easing: "ease-in-out",
  });

  const leftMouseOver = () => {
    setLeftMouse(true);
  };
  const leftMouseOut = () => {
    setLeftMouse(false);
  };
  const rightMouseOver = () => {
    setRightMouse(true);
  };
  const rightMouseOut = () => {
    setRightMouse(false);
  };

  return (
    <TextWrapper bg={bg}>
      <div>
        <Category bg={bg}>
          <p>{detail.category}</p>
        </Category>
        <TitleWrapper>
          {detail.title.map((sub) => (
            <Title key={sub}>
              <p>{sub}</p>
            </Title>
          ))}
        </TitleWrapper>
        {detail.subtitle.map((sub) => (
          <Subtitle key={sub}>
            <p>{sub}</p>
          </Subtitle>
        ))}
      </div>

      <DownloadWrap mobile={true} bg={bg}>

        <RoundButton
          url={ 
            "https://apps.apple.com/kr/app/세모스/id1578637889"
          }
          blue={blue}
          onMouseOver={leftMouseOver}
          onMouseOut={leftMouseOut}
        >
          {<AppImg bg={bg} LeftMouse={LeftMouse}></AppImg>}
          {lang ? 'App Store' : '앱스토어 바로가기'}
        </RoundButton>

        {<RoundButton
          url={
            "https://play.google.com/store/apps/details?id=com.report.semos"
          }
          onMouseOver={rightMouseOver}
          onMouseOut={rightMouseOut}
          blue={blue}
        >
          <Img bg={bg} RightMouse={RightMouse}></Img>
          {lang ? 'Play Store' : '플레이스토어 바로가기'}
        </RoundButton>}

      </DownloadWrap>

    </TextWrapper>
  );
}

export default DetailTextSectionB2C;

const Img = styled.div`
  background: ${({ RightMouse, bg }) =>
    bg === "blue"
      ? RightMouse
        ? `url(${GoogleStore})`
        : `url(${GoogleStoreW})`
      : RightMouse
      ? `url(${GoogleStoreW})`
      : `url(${GoogleStore})`};
  background-position: center;
  background-size: cover;
  width: 21px;
  height: 21px;
  margin-right: 8.5px;
`;
const AppImg = styled.div`
  background: ${({ LeftMouse, bg }) =>
    bg === "blue"
      ? LeftMouse
        ? `url(${AppStore})`
        : `url(${AppStoreW})`
      : LeftMouse
      ? `url(${AppStoreW})`
      : `url(${AppStore})`};
  background-position: center;
  background-size: cover;
  width: 21px;
  height: 21px;
  margin-right: 8.5px;
`;

const DownloadWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
  color: ${({ bg }) => (bg === "blue" ? colors.white : colors.blue07)};

  @media screen and (min-width: 561px) and (max-width: 1024px) {
    margin-top: 50px;
    gap: 20px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 560px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }
`;

const TitleWrapper = styled.div`
  margin: 20px 0;
`;

const TextWrapper = styled.div`
  width: 460px;
  height: 533px;
  @media screen and (max-width: 1024px) {
    margin: 120px 0 30px 0;
    width: auto;
    height: auto;
  }
  @media screen and (max-width: 560px) {
    margin-top: 121px;
    margin-bottom: 0;
    align-items: center;
    text-align: center;
  }
  color: ${({ bg }) => (bg === "blue" ? colors.white : colors.black)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Category = styled.div`
  color: ${({ bg }) => (bg === "blue" ? colors.white : colors.blue07)};
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 142%;

  @media screen and (max-width: 560px) {
    margin-bottom: 0px;
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 44px;
  font-weight: 700;
  line-height: 132.5%;
  @media screen and (max-width: 560px) {
    font-size: 22px;
    line-height: 142%;
  }
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 142%;
  letter-spacing: -0.02em;
  @media screen and (max-width: 560px) {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.02em;
    }
  }
`;
