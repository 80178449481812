import React from "react";
import styled from "styled-components";
import colors from "../styles/constants/colors";
import "aos/dist/aos.css";
import DetailTextSection from "./DetailTextSection";
import flexbox from "../styles/function/flexbox";
import DetailMockUpPartnerSection from "./DetailMockUpPartnerSection";

function DetailSemosPartner({lang}) {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const detail = {
    category: "For SEMOS Partners",
    title: mobileCheck
      ? ["스포츠 강사/시설을 위한", "판매관리 솔루션 '세모스 파트너'"]
      : ["스포츠 강사/시설을 위한", "판매관리 솔루션", "'세모스 파트너'"],
    subtitle: [
      "전용 홈페이지 제작부터 예약 관리까지",
      "세모스 파트너로 쉽고, 빠르게 고객을 만나보세요",
    ],
  };

  const detailEng = {
    category: "For SEMOS Partners",
    title: mobileCheck
      ? ["Sales management solution for sports", "instructors/facilities 'Semos Partner'"]
      : ["Sales management solution for sports", "instructors/facilities", "'Semos Partner'"],
    subtitle: [
      "Connect with your team and customers in the easiest way possible",
      "Gather your sports crew (customers) and explore all the sports in the world with SEMOS",
    ],
  };
  return (
    <Section>
      <Wrap>
        <DetailMockUpPartnerSection mobile={true} />
        <DetailTextSection
          mobile={true}
          pc={true}
          detail={lang ? detailEng : detail}
          blue={false}
          bg="blue"
          lang={lang}
        />
      </Wrap>
    </Section>
  );
}

export default DetailSemosPartner;

const Section = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  color: black;
  flex-wrap: wrap;
  background-color: ${colors.blue07};
  ${flexbox("center", "center", "row")}
  position: relative;
  @media screen and (min-width: 561px) and (max-width: 1024px) {
    ${flexbox("center", "center", "column")}
    height: auto;
    min-height: 100vh;
  }

  @media screen and (max-width: 560px) {
    height: auto;
    min-height: 100vh;
  }
`;

const Wrap = styled.div`
  ${flexbox("center", "center", "row")}
  gap: 109px;

  @media screen and (min-width: 561px) and (max-width: 1024px) {
    ${flexbox("center", "center", "column-reverse")}
    padding-bottom: 100px;
    gap: 0px;
  }
  @media screen and (max-width: 560px) {
    ${flexbox("center", "center", "column-reverse")}
    gap: 0;
    padding-bottom: 0px;
  }
`;
