import { useEffect } from "react";
import styled from "styled-components";

// components
import FloatingBox from "../components/FloatingBox";
import Preface from "../components/preface";
import colors from "../styles/constants/colors";
import alignCenter from "../styles/function/alignCenter";

// images
import DownScrollIcon from "../assets/icon/scrollDownBlack.png";


const Wrapper = styled.div`
  /* width: 100%;
  height: 100%; */
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const BannerSection = ({lang}) => {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const topTitle = "For your Sporty Lifestyle";
  // const title = lang ? ["Energize and elevate", "the world through sports"] : ["세상을 더 에너제틱하게,", "더 스포티하게 만드는 곳"];
  const title = lang ? ["Energize and elevate", "the world through sports"] : ["세상을 더 에너제틱하게,", "더 스포티하게 만드는 곳"];
  const subtitle = mobileCheck
    ? [
        "언제 어디서든 누구나 스포츠를 즐길 수 있도록",
        "당신의 스포티한 라이프스타일을 위한",
        "스포츠테크 리포츠입니다.",
      ]
    : [
        "언제 어디서든 누구나 스포츠를 즐길 수 있도록",
        "당신의 스포티한 라이프스타일을 위한 스포츠테크 리포츠입니다.",
      ];

      

  const subtitleEn = mobileCheck
    ? [
        "Enabling universal access to sports, anytime and anywhere.",
        "The sports tech company",
        "for your active lifestyle.",
      ]
    : [
        "Enabling universal access to sports, anytime and anywhere.",
        "The sports tech company for your active lifestyle.",
      ];

  return (
    <>
      <Wrapper>
        <Preface
          topTitle={topTitle}
          title={title}
          subtitle={lang ? subtitleEn : subtitle}
          color={colors.white}
          titleTop={true}
        />
      </Wrapper>
      <FloatingBox scrollId="firstScroll" boxIcon={DownScrollIcon} />
    </>
  );
};

export default BannerSection;
