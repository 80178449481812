import React from "react";
import styled from "styled-components";
// import RecruitImg from "../assets/icon/recruitImg.png";
import RecruitImg from "../assets/icon/recruitImg.jpg";
import RecruitImgM from "../assets/icon/recruitImgM.png";
import flexbox from "../styles/function/flexbox";
import TopText from "./elements/TopText";

function DetailRecruit({lang}) {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const data = [
    {
      top: "Semos Partner Recruitment",
      title: mobileCheck
        ? ["세모스 파트너는 지금", "스포츠 시설/강사 파트너 모집 중!"]
        : ["세모스 파트너는", " 지금 스포츠 시설/강사", "파트너 모집 중!"],

      subTitle: ["세모스 파트너와 함께, 언제 어디서든 고객을 만나보세요!"],
      buttonText: "파트너 등록하기"/*"등록 상담 신청하기"*/,
    },
  ];

  const dataEng = [
    {
      top: "Semos Partner Recruitment",
      title: mobileCheck
        ? ["Seize the opportunity to excel ", "in the winter sports industry"]
        : ["Seize", "the opportunity to excel", "in the winter sports industry"],

      subTitle: ["Whether you're a skiing or snowboarding instructor or own a sports center,", "register with Semos without delay."],
      buttonText: "JOIN NOW",
    },
  ];

  return (
    <>
      <Wrap>
        <TopText
          top={true}
          paddingTop="91px"
          paddingBottom="91px"
          mobilePaddingTop="69px"
          mobilePaddingBottom="92px"
          data={lang ? dataEng : data}
          buttonUrl="https://partner.semos.kr"/*'https://forms.gle/mpMXYxmcxyXetueB8'*/
        />
      </Wrap>
    </>
  );
}

export default DetailRecruit;

const Wrap = styled.div`
  height: auto;
  color: black;
  background-position: center;
  background-image: url(${RecruitImg});
  background-size: cover;
  gap: 58px;
  @media screen and (max-width: 1024px) {
    .TopText {
      ${flexbox("flex-end", "flex-start", "column")}
    }
    .roundButton {
    }
  }
  @media screen and (max-width: 560px) {
    background-image: url(${RecruitImgM});
    padding: 0;
    ${flexbox("center", "center", "column")}
    text-align: center;
    height: 365px;
  }
`;
