import SemosMockUpPartner01 from "../assets/images/mockUp/semosMockUpPartner01.png";
import SemosMockUpPartner02 from "../assets/images/mockUp/semosMockUpPartner02.png";
import SemosMockUpPartner03 from "../assets/images/mockUp/semosMockUpPartner03.png";
import SemosMockUpPartner04 from "../assets/images/mockUp/semosMockUpPartner04.png";
import SemosMockUpPartner05 from "../assets/images/mockUp/semosMockUpPartner05.png";
import SemosMockUpPartner06 from "../assets/images/mockUp/semosMockUpPartner06.png";

const partnerMockUp = [
  {
    id: 1,
    img: SemosMockUpPartner01,
  },
  {
    id: 2,
    img: SemosMockUpPartner02,
  },
  {
    id: 3,
    img: SemosMockUpPartner03,
  },
  {
    id: 4,
    img: SemosMockUpPartner04,
  },
  {
    id: 5,
    img: SemosMockUpPartner05,
  },
  {
    id: 6,
    img: SemosMockUpPartner06,
  }
];

export default partnerMockUp;
