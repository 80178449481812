import styled from "styled-components";
import { useEffect } from "react";

// components
import BannerSection from "../layouts/bannerSection";
import DetailSectionDesktop from "../components/DetailSectionDesktop";
import DetailCard from "../components/DetailCard";
import DetailSemosPartner from "../components/DetailSemosPartner";
import DetailReview from "../components/DetailReview";
import DetailRecruit from "../components/DetailRecruit";

// components
import MainBgJpg from "../assets/images/section/reportsHome1.jpg";
import MainBgM from "../assets/images/section/mainBgM.png";

const HomePage = () => {
  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  const Locale = window.localStorage.getItem("locale");

  let Lang = "";

  if(Locale) {
    if(Locale == 'KOR') {
      Lang = true;
    } else {
      Lang = false;
    }
  }

  return (
    <>
      <NonMobileSection>
        <BannerSection lang={Lang} />
      </NonMobileSection>
      <DetailSemosPartner lang={Lang} />
      <DetailCard lang={Lang} />
      <DetailRecruit lang={Lang} />

      <DetailSectionDesktop lang={Lang} />
      <DetailReview lang={Lang} />
    </>
  );
};

export default HomePage;

const NonMobileSection = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: url(${MainBgJpg});
  background-position: center;
  background-size: cover;

  @media screen and (max-width: 560px) {
    height: calc(var(--vh, 1vh) * 50);
    //background: url(${MainBgM});
    background-position: center;
    background-size: cover;
  }
`;
