import React from "react";
import styled from "styled-components";
import colors from "../styles/constants/colors";
import "aos/dist/aos.css";
import DetailTextSectionB2C from "./DetailTextSectionB2C";
import DetailMockUpSection from "./DetaiMockUplSection";
import flexbox from "../styles/function/flexbox";

function DetailSectionDesktop({lang}) {
  const mobileCheck =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  const detail = {
    category: "For SEMOS Users",
    title: mobileCheck
      ? ["전문성을 가진 스포츠 강사/시설과", "스포츠를 즐길 수 있도록."]
      : ["전문성을 가진", "스포츠 강사/시설과", "스포츠를 즐길 수", "있도록."],
    subtitle: [
      "레슨/자격증/투어/이용권 등 7개 카테고리",
      "대한민국 No. 1 스포츠 예약 플랫폼",
      "세상의 모든 스포츠, 세모스",
    ],
  };

  const detailEng = {
    category: "For SEMOS Users",
    title: mobileCheck
      ? ["To enjoy sports with", "professional instructors and facilities"]
      : ["To enjoy sports with", "professional instructors", "and facilities"],
    subtitle: [
      "across seven categories including lessons, certifications, tours, and passes",
      "Korea's largest and premier sports program platform SEMOS",
    ],
  };

  return (
    <Section id="firstScroll">
      <Wrap>
        <DetailTextSectionB2C blue={true} detail={lang ? detailEng : detail} lang={lang} />
        <DetailMockUpSection />
      </Wrap>
    </Section>
  );
}

export default DetailSectionDesktop;
const Section = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  color: black;
  flex-wrap: wrap;
  background-color: ${colors.white};
  ${flexbox("center", "center", "row")}
  position: relative;

  @media screen and (max-width: 1024px) {
    padding: 0px;
    height: auto;
    min-height: 100vh;
  }
`;

const Wrap = styled.div`
  ${flexbox("center", "center", "row")}
  gap: 44.5px;

  @media screen and (min-width: 561px) and (max-width: 1024px) {
    ${flexbox("center", "center", "column")}
    padding-bottom: 100px;
  }
  @media screen and (max-width: 560px) {
    ${flexbox("center", "center", "column")}
    gap: 0;
    padding-bottom: 50px;
  }
`;
