import review001 from "../assets/images/review/review-1.png";
import review002 from "../assets/images/review/review-2.png";
import review003 from "../assets/images/review/review-3.png";
import review004 from "../assets/images/review/review-4.png";
import review005 from "../assets/images/review/review-5.png";
import review006 from "../assets/images/review/review-6.png";
import ReviewImg from "../assets/icon/reviewImg.png";

const review = [
  {
    id: 1,
    nickname: "솜****",
    review:
      "몰디브 신혼여행을 앞두고 이현강사님의 원데이 클래스를 듣고왔습니다! 물 속에 잠수하는것 자체가 처음이어서 긴장하고 갔는데 기초부터 쉽게 설명해주셔서 잘 따라할수 있었어요:) 운동 신경이 좋은 남편은 몇번 해보더니 쉽게 따라했는데, 상태가 영 안좋은 저는 많이 버벅였습니다:웃으며_눈물을_흘리는_얼굴: 그래도 강사님이 끝까지 도와주셨고 사진도 건졌습니다! 모두에게 추천해요:+1::피부톤-2:",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221028162734501_2.jpeg",
    star: 5,
    item: "수중 인생샷 전문 원데이 프리다이빙 클래스",
  },
  {
    id: 2,
    nickname: "셀****",
    review:
      "수영을 배웠지만 물이 무서운 저에게는 큰 도전이였는데 강사님께서 물공포증에 대한 공감도 해주시고 용기를 북돋아주셔서 베이직과정 잘 마쳤습니다. 마지막에는 제가 체력때문에 집중력이 다 떨어졌지만 강사님께서 잘 이끌어주셨습니다. 다이빙에대한 정보가 하나도 없었지만 운이 좋게 좋은 선생님을 잘 만난거 같아요.용기내길 잘했다는 생각이 들어요 추천합니다~!!",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221128175246563_0.jpeg",
    star: 5,
    item: "PADI Basic 프리다이빙 과정",
  },
  {
    id: 3,
    nickname: "찌****",
    review:
      "친절하신 강사님 덕분에 용기내서 덕다이빙도 성공했습니당! 추천드려요!",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221107152202541_0.jpeg",
    star: 5,
    item: "수중 촬영 맛집! 원데이 프리다이빙 체험",
  },
  {
    id: 4,
    nickname: "안****",
    review:
      "너무 즐거운 시간이었습니다!! 강사님께서 친절하게 수업도 잘 알려주시고 유쾌하기도 하셔서 재미까지 챙겨주시는 알찬 수업이었습니다~!!ㅎㅎ 처음엔 허둥대고 귀도 너무 아프고해서 되겠나 했는데 강사님께서 잘 적응할 수 있게 지도해주셔서 끝에는 수업 마치기 아쉬웠습니다ㅎㅎ 사진도 엄청 적극적으로 찍어주십니다!!친구들과 같이 하면 더 재밌는거 같아요!!추천!!",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221030154354523_0.jpeg",
    star: 5,
    item: "수중 인생샷 전문 원데이 프리다이빙 클래스",
  },
  {
    id: 5,
    nickname: "바****",
    review:
      "수영 못해도 상관없어요 :> 선생님 전문적으로 잘 가르쳐주십니다! 너무×100 재밌는 시간이었습니다~~~",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221218170310555_0.jpeg",
    star: 5,
    item: "수중 촬영 맛집! 원데이 프리다이빙 체험",
  },
  {
    id: 6,
    nickname: "s****",
    review:
      "처음 해보는 활동이고 물 공포증도 있어서 많이 긴장하고 시작했는데 강사님께서 시작부터 끝까지 너무 잘 이끌어주셔서 어색하지도 않고 너무 재밌게 잘 즐겼어요 !!! 가기 전엔 원데이레슨으로 뭐 하나 건지려나..싶었는데 물 공포증도 없어지고 강사님이 좋은 영상,사진 찍어서 편집도 너무 예쁘게 잘 해주셔서 진짜 같이 간 친구들도 대만족입니다!!ㅋㅋㅋㅋㅋㅋ",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221013220907515_1.jpeg",
    star: 5,
    item: "[평일] 부산 프리다이빙 풀패키지 원데이 레슨",
  },
  {
    id: 7,
    nickname: "G****",
    review:
      "휴일, 물을 너무나 좋아하는 초등6학년 아들과 멋진 추억이 되었습니다. 친철한 강사님 가이드에 처음이었지만 재밌는 다이빙을 체험했어요! 완전 강추!",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20221003213405500_0.jpeg",
    star: 5,
    item: "[주말] 수원 스쿠버다이빙 풀패키지 원데이 레슨",
  },
  {
    id: 8,
    nickname: "크****",
    review:
      "최고의 강사님 추천합니다 수영 잘 못하고 물 무서워하는 편인데 선생님이 정말정말 세심하게 잘 가르쳐주십니다! 덕분에 정말 안전하게 재밌게 수업 들을수 있었어요~~! 원데이 레슨으로 이퀄라이징 덕다이빙도 배우고 물이랑도 많이 친해져서 여행가서 훨씬 재밌게 놀수있었어요:)",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20220930093909472_1.jpeg",
    star: 5,
    item: "[평일] 서울 프리다이빙 풀패키지 원데이 레슨",
  },
  {
    id: 9,
    nickname: "흥****",
    review:
      "원데이 체험이라서 큰기대안하고 신청했었는데 너무 만족스러운 시간이였습니다 강사님이 너무 잘해주셔서 감사했습니다  프리다이빙도 좋았지만. 아이들이 자신이 좋하하는 일을하면 저렇게 눈반짝이면서 즐겁게 할수있구나를 느끼더라구요 열정에 감동했습니다 그리고 디테일한부분까지 신경써서 알려주시고 마직막 덕다운까지 많은걸 배울수있어서 더좋았습니다 많은분들이 체험해보셨으면좋겠습니다",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20220926105130473_0.jpeg",
    star: 5,
    item: "[주말] 서울 프리다이빙 풀패키지 원데이 레슨",
  },
  {
    id: 10,
    nickname: "당***",
    review:
      "급하게 여행일정을 잡고나서야 원데이 프리다이빙 수업을 알게되어서 출발 하루전날에 수업을 진행하게 되었습니다. 강사님께서 사정을 들으시고는 이론수업을 간략하게 줄여주시고 생존용ㅎ 실전을 더 집중해서 해주셨어요 수업 너무 즐거웠고, 덕분에 하루의 수업만으로도 확실히 다른 스노쿨을 즐길 수 있었습니다! 사진도 많이 찍어주시고 편집영상도 재밌는 추억이될것 같아요~",
    img: "https://ai.semos.kr/img/user_comment/userCommentImg20220922103821000000_437_248.jpeg",
    star: 5,
    item: "수중 인생샷 전문 원데이 프리다이빙 클래스",
  },
  {
    id: 11,
    nickname: "유****",
    review:
      "오후 2시 타임 강습이었는데 너무 친절하고 열정적으로 잘 가르쳐 주셨어요! 처음엔 감을 잡기 어려웠는데 강사님이 알려주신 팁을 따라하니 어느정도 흉내는 낼 수 있게 되었어요 ㅎㅎ 종종 취미로 즐기려 또 신청할 것 같습니다 :미소짓는_얼굴:",
    img: ReviewImg,
    star: 5,
    item: "수중 인생샷 전문 원데이 프리다이빙 클래스",
  },
  // {
  //   id: 0,
  //   img: review001,
  //   name: "세모스 크루",
  //   comment:
  //     "처음 접하는 프리다이빙임에도 불구하고 강사님의 친절한 설명덕에 이해하기 쉬웠고 반복적으로 강습생을 위해 자세한 부가설명이 너무 좋았습니다 ~",
  // },
  // {
  //   id: 1,
  //   img: review002,
  //   name: "세모스 강사",
  //   comment:
  //     "신규 수강생 문제로 고민이 많았는데, 세모스 덕분에 좋은 인연이 닿을 수 있어 항상 감사합니다. ^^",
  // },
  // {
  //   id: 2,
  //   img: review003,
  //   name: "세모스 크루",
  //   comment:
  //     "강습 예약하는게 항상 번거로웠는데 간편하게 예약할 수 있어서 참 좋아요. 세모스 도장깨기로 다른 종목들도 경험하고 싶네요. ㅎㅎㅎ",
  // },
  // {
  //   id: 3,
  //   img: review004,
  //   name: "세모스 강사",
  //   comment:
  //     "멋진 상세페이지 제작해주셔서 감사해요!! 세모스를 통해서 더 많은 학생들과 소통하고 싶어요~",
  // },
  // {
  //   id: 4,
  //   img: review005,
  //   name: "세모스 크루",
  //   comment:
  //     "물 무서워하는데 너무너무 재밌더라구요. 그냥 좋았어요^^ 새로운 경험이였고 재밌고 즐겁게 배울 수 있었어요. 강사님 너무 친절하세요!",
  // },
  // {
  //   id: 5,
  //   img: review006,
  //   name: "세모스 크루",
  //   comment:
  //     "서핑의 역사부터 체계적으로 서핑에 대해 알게된 것 같아 좋았고, 강사님이 한결같이 웃으면서 잘 설명해주셔서 좋았어요. 프리다이빙도 배워보려구요!",
  // },
];

export default review;
