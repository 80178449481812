import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import DownArrow from "../assets/icon/downArrowBlack.png";
import flexbox from "../styles/function/flexbox";

const Wrapper = styled.div`
  ${flexbox("center", "center", "column")};
  cursor: pointer;
`;

const ImgSize = keyframes`
  0% {
  bottom: 30px;
 
  }
  100% {
  bottom: 45px;
  }
`;

const Img = styled.img`
  width: auto;
  height: 32px;
  animation: ${ImgSize} 0.8s linear 0s infinite alternate;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImgIcon = styled.img`
  width: 20px;
  //height: 8.75px;
  margin-top: 14.25px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 18px;
`;
const FloatingBox = ({ boxIcon, scrollId }) => {
  let element = "";
  const button = () => {
    setTimeout(() => {
      if (scrollId === "firstScroll") {
        element = document.getElementById("firstScroll");
      } else {
        element = document.getElementById("secondScroll");
      }
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 500);
  };

  return (
    <>
      <Wrapper onClick={button}>
        <Img src={boxIcon} />
        <ImgIcon src={DownArrow} />
      </Wrapper>
    </>
  );
};

export default FloatingBox;
