import styled from "styled-components";
import colors from "../../styles/constants/colors";
import flexbox from "../../styles/function/flexbox";
import Container from "../responsive/Container";
import RoundButton from "./RoundButton";

const Wrap = styled.div`
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "0px")};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : "0px"};
  display: flex;
  text-align: ${({ center }) => (center ? "center" : "flex-start")};
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  justify-content: ${({ center }) => (center ? "center" : "space-between")};

  font-weight: 700;
  color: ${({ black }) => (black ? colors.gray05 : colors.white)};
  line-height: 142%;
  position: relative;
  @media screen and (max-width: 1024px) {
    ${flexbox("flex-end", "center", "column")}
  }
  @media screen and (max-width: 560px) {
    gap: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: ${({ mobilePaddingTop }) =>
      mobilePaddingTop ? mobilePaddingTop : "0px"} !important;
    padding-bottom: ${({ mobilePaddingBottom }) =>
      mobilePaddingBottom ? mobilePaddingBottom : "0px"}!important;

    ${flexbox("flex-end", "center", "column")}
  }
`;

const Top = styled.p`
  font-size: 16px;
  color: ${({ black }) => (black ? colors.blue07 : colors.blue03)};
  @media screen and (max-width: 560px) {
    font-size: ${({ top }) => (top ? "16px" : "14px")};
  }
`;

const Title = styled.div`
  margin: 20px 0 15px 0;
  @media screen and (max-width: 560px) {
    margin: ${({ top }) => (top ? "18px 0 10px 0" : "20px 0 10px 0")};
  }
  p {
    font-size: 48px;
    line-height: 130%;
    @media screen and (max-width: 1024px) {
      font-size: 38px;
      /* font-size: 2.5rem; */
      /* font-size: 25px; */
      /* line-height: 142%;
      font-size: ${({ top }) => (top ? "24px" : "20px")}; */
    }
    @media screen and (max-width: 560px) {
      line-height: 142%;
      font-size: ${({ top }) => (top ? "24px" : "20px")};
    }
  }
`;

const SubTitle = styled.div`
  p {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -0.02em;
    @media screen and (max-width: 560px) {
      font-size: ${({ top }) => (top ? "12px" : "14px")};
    }
  }
`;

const ButtonWrap = styled.div`
  text-align: center;
  align-self: center;
  @media screen and (max-width: 1024px) {
    align-self: flex-start;
  }
  @media screen and (max-width: 560px) {
    align-self: center;
    margin-top: 36px;
  }
`;
const TextWrap = styled.div`
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
`;

const TopText = ({
  children,
  data,
  center,
  black,
  top,
  paddingTop,
  buttonUrl,
  paddingBottom,
  mobilePaddingTop,
  mobilePaddingBottom,
}) => {
  return (
    <Container>
      {data.map((item) => (
        <Wrap
          className="TopText"
          key={item}
          center={center}
          black={black}
          mobilePaddingTop={mobilePaddingTop}
          mobilePaddingBottom={mobilePaddingBottom}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
        >
          <TextWrap center={center}>
            <Top black={black} top={top}>
              {item.top}
            </Top>
            <div>
              <Title top={top}>
                {item.title.map((t) => (
                  <p key={t}>{t}</p>
                ))}
              </Title>
            </div>
            <SubTitle top={top}>
              {item.subTitle.map((t) => (
                <p key={t}>{t}</p>
              ))}
            </SubTitle>
          </TextWrap>
          <ButtonWrap className="buttonWrap">
            {item.buttonText && (
              <RoundButton url={buttonUrl} blue={black}>
                {item.buttonText}
              </RoundButton>
            )}
          </ButtonWrap>
        </Wrap>
      ))}

      {children}
    </Container>
  );
};

export default TopText;
