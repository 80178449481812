import React from "react";
import styled from "styled-components";
import colors from "../../styles/constants/colors";
import RecruitImg from "../../assets/icon/recruitImg.png";
import flexbox from "../../styles/function/flexbox";

function RoundButtonBig({
  children,
  icon,
  url,
  blue,
  onClick,
  onMouseOver,
  onMouseOut,
}) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <CardPerformance
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        blue={blue}
        className="roundButton"
      >
        {children}
      </CardPerformance>
    </a>
  );
}

export default RoundButtonBig;

const CardPerformance = styled.div`
  color: ${({ blue }) => (blue ? colors.blue07 : colors.white)};
  border-radius: 30px;
  font-weight: 700;
  font-size: 18px;
  line-height: 142%;
  border: 1.6px solid ${({ blue }) => (blue ? colors.blue07 : colors.white)};
  padding: 10px 18px;
  display: inline-flex;
  flex-direction: row;
  :hover {
    color: ${({ blue }) => (blue ? colors.white : colors.blue07)};
    background-color: ${({ blue }) => (blue ? colors.blue07 : colors.white)};
  }

  @media screen and (max-width: 560px) {
    font-size: 14px;
    padding: 8px 14px;
  }
`;
