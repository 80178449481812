import styled from "styled-components";
import responsive from "../../styles/constants/responsive";

const SContainer = styled.div`
  /* padding: 0px 360px; */
  padding: 0px 18vw;
  @media screen and (max-width: 1024px) {
    padding: 0 8vw;
  }
  @media screen and (max-width: 560px) {
    padding: 0;
  }
`;

const Container = ({ children }) => {
  return <SContainer>{children}</SContainer>;
};

export default Container;
