import SemosMockUp01 from "../assets/images/mockUp/semosMockUp01.png";
import SemosMockUp02 from "../assets/images/mockUp/semosMockUp02.png";
import SemosMockUp03 from "../assets/images/mockUp/semosMockUp03.png";
import SemosMockUp04 from "../assets/images/mockUp/semosMockUp04.png";

const mockUpImg = [
  {
    id: 1,
    img: SemosMockUp01,
  },
  {
    id: 2,
    img: SemosMockUp02,
  },
  {
    id: 3,
    img: SemosMockUp03,
  },
  {
    id: 4,
    img: SemosMockUp04,
  },
];

export default mockUpImg;
