import React from "react";
import styled from "styled-components";
import colors from "../styles/constants/colors";
import flexbox from "../styles/function/flexbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function DetailCard({lang}) {
  const cardData = [
    {
      title: ["함께하는", "강사/시설 파트너"],
      performance: "200",
      performanceText: "인+",
    },

    {
      title: ["세모스가 줄여드린", "문자 발송 횟수"],
      performance: "4,300",
      performanceText: "회+",
    },

    {
      title: ["파트너가 고객에게", "노출되는 횟수"],
      performance: "13,000",
      performanceText: "회+",
    },

    {
      title: ["세모스로 스포츠 프로그램을 찾는", " "],
      performance: "300,000",
      performanceText: "번의 클릭",
    },

    {
      title: ["세모스로 스포츠 프로그램을", "예약하는 유저"],
      performance: "25,000",
      performanceText: "인+",
    },

    {
      title: ["세모스에서 만날 수 있는", "스포츠 프로그램 수"],
      performance: "800",
      performanceText: "개+",
    },

    {
      title: ["플레이/앱 스토어", "최고 순위"],
      performance: "10",
      performanceText: "위",
    }
  ];

  const cardDataEng = [
    {
      title: ["Partner", "sports instructors/facilities"],
      performance: "200",
      performanceText: "+",
    },

    {
      title: ["The number of text messages", "reduced by Semos"],
      performance: "4,300",
      performanceText: "+",
    },

    {
      title: ["The number of times", "partners are exposed to customers"],
      performance: "13,000",
      performanceText: "+",
    },

    {
      title: ["Number of times users clicked to find", "sports activities on SEMOS this year"],
      performance: "300,000",
      performanceText: "Clicks"
    },

    {
      title: ["Using Semos to reservation", "sports programs"],
      performance: "25,000",
      performanceText: "+",
    },

    {
      title: ["Activities available in", "real-time on SEMOS"],
      performance: "800",
      performanceText: "+",
    },

    {
      title: ["The highest ranking on the", "Play/App Store"],
      performance: "10",
      performanceText: "",
    }
  ];

  const Card = lang ? cardDataEng : cardData;

  return (
    <>
      <Section>
        <Wrap>
          <Slider
            className="slider variable-width"
            cssEase="linear"
            arrows={false}
            dots={false}
            infinite={true}
            variableWidth={true}
            slidesToScroll={1}
            autoplay={true}
            rows={1}
            speed={5000}
            autoplaySpeed={0}
            pauseOnHover={true}
          >
            {Card.map((data) => (
              <Box key={data}>
                <CardBox key={data.performance}>
                  <div>
                    {data.title.map((item) => (
                      <p key={item}>{item}</p>
                    ))}
                  </div>
                  <CardPerformance>
                    <p>{data.performance}</p>
                    <p>{data.performanceText}</p>
                  </CardPerformance>
                </CardBox>
              </Box>
            ))}
          </Slider>
        </Wrap>
      </Section>
    </>
  );
}

export default DetailCard;

const Section = styled.div`
  height: 420px;
  color: black;
  background-color: #f9f9f9;
  ${flexbox("center", "center", "row")}
  @media screen and (max-width: 560px) {
    height: 168px;
  }
`;
const Wrap = styled.div`
  width: 100%;
  @media screen and (max-width: 335px) {
    margin: 120px 0 0 0;
  }
`;
const CardPerformance = styled.div`
  text-align: end;
  color: ${colors.blue07};
  display: flex;
  ${flexbox("flex-end", "flex-end", "row")}
  p:nth-child(1) {
    font-weight: 700;
    font-size: 52px;
    line-height: 142%;
    text-align: right;
    letter-spacing: -0.01em;
    @media screen and (max-width: 560px) {
      font-size: 25px;
    }
  }
  p:nth-child(2) {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 142%;
    letter-spacing: -0.01em;
    @media screen and (max-width: 560px) {
      font-size: 12px;
      margin: 0 0 7px 8px;
    }
    margin: 0 0 17px 8px;
  }
`;

const CardBox = styled.div`
  width: 492px;
  height: 224px;
  p {
    font-weight: 700;
    font-size: 22px;
    line-height: 142%;
  }
  background-color: ${colors.white};
  border: 1px solid #dcdcdc;
  box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  color: ${colors.black};
  padding: 40px 40px 10px 40px;
  /* margin: 20px; */
  ${flexbox("space-between", "space-between", "column")}
  @media screen and (max-width: 560px) {
    border-radius: 11.9471px;
    box-shadow: 1.5px 1.5px 12.5px rgba(0, 0, 0, 0.25);
    width: 264px;
    height: 113px;
    padding: 21px 14px 12px 21px;
    p {
      font-size: 14px;
    }
  }
`;
const Box = styled.div`
  /* width: 500px; */
  margin: 30px 29px;
  flex-wrap: nowrap;
  @media screen and (max-width: 560px) {
    margin: 10px 10px;
    /* width: 200px;
    height: 100px;
    margin: 20px;
    margin-bottom: 50px; */
  }
`;
