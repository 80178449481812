import styled from "styled-components";
import colors from "../styles/constants/colors";

const Header = styled.div`
  color: white;
  text-align: center;
`;

const TopTitle = styled.div`
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 142%;
    margin-bottom: 20px;
    color: ${colors.skyBlue};
    text-shadow: 2px 3px 5px ${colors.gray05};

    @media screen and (max-width: 560px) {
      font-size: 14px;
    }
  }
`;

const Title = styled.div`
  p {
    font-weight: 700;
    font-size: 66px;
    line-height: 142%;
    text-shadow: 2px 3px 5px ${colors.gray05};

    @media screen and (max-width: 1024px) {
      font-size: 50px;
    }
    @media screen and (max-width: 560px) {
      font-size: 24px;
    }
  }
`;

const Subtitle = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;

  @media screen and (max-width: 560px) {
    margin-top: 10px;
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 142%;
    letter-spacing: -0.02em;
    text-shadow: 2px 3px 5px ${colors.gray05};
    
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
    @media screen and (max-width: 560px) {
      font-size: 14px;
    }
  }
`;

const Preface = ({
  children,
  topTitle = "",
  title = [],
  subtitle = [],
  color,
  titleTop = false,
  center = false,
  alignCenter = false,
  titleFont = "base",
}) => {
  return (
    <Header color={color} center={center}>
      <TopTitle>
        <p>{topTitle}</p>
      </TopTitle>
      <Title
        titleTop={titleTop}
        center={center}
        alignCenter={alignCenter}
        titleFont={titleFont}
      >
        {title.map((t, idx) => (
          <p key={idx}>{t}</p>
        ))}
      </Title>

      <Subtitle alignCenter={alignCenter}>
        {subtitle.map((sub, idx) => (
          <p key={idx}>{sub}</p>
        ))}
      </Subtitle>
      {children}
    </Header>
  );
};

export default Preface;
