import React from "react";
import styled, { css } from "styled-components";
import colors from "../styles/constants/colors";
import flexbox from "../styles/function/flexbox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mockUpImg from "../data/mockUpImg";

function DetailMockUpSection() {
  return (
    <>
      <Section>
        <PhoneWrapper>
          <Wrap mobile={true}>
            <Slider
              cssEase="linear"
              arrows={false}
              dots={false}
              infinite={true}
              slidesToShow={1}
              centerMode={true}
              centerPadding="100px"
              pauseOnHover={false}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={4000}
            >
              {mockUpImg.map((item) => (
                <MockImageCenter key={item.id} src={item.img}></MockImageCenter>
              ))}
            </Slider>
          </Wrap>
          <Wrap pc={true}>
            <Slider
              cssEase="linear"
              arrows={false}
              dots={false}
              infinite={true}
              slidesToShow={3}
              centerMode={true}
              centerPadding="0px"
              pauseOnHover={false}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3500}
            >
              {mockUpImg.map((item) => (
                <MockImageCenter key={item.id} src={item.img}></MockImageCenter>
              ))}
            </Slider>
          </Wrap>
        </PhoneWrapper>
      </Section>
    </>
  );
}

export default DetailMockUpSection;

const Section = styled.div`
  background-color: ${colors.white};
  ${flexbox("center", "center", "row")}
  position: relative;
`;

const PhoneWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${flexbox("center", "center", "row")}
  gap: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 560px) {
    height: auto;
    margin-bottom: 0px;
  }
`;

const MockImageCenter = styled.img`
  padding: 40px 30px 20px 30px;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 560px) {
    padding: 40px 20px 20px 20px;
  }
  z-index: 0;
  filter: drop-shadow(6px -2px 6px rgba(0, 0, 0, 0.25))
    drop-shadow(-6px 5px 6px rgba(0, 0, 0, 0.25));
`;

const Wrap = styled.div`
  z-index: 0;
  background-color: transparent;
  max-width: 780px;
  width: 40vw;
  ${({ pc }) =>
    pc &&
    css`
      .slick-slide {
        transform: scale(1.1);
        transition: transform 0.3s linear;
      }
      .slick-center {
        align-self: flex-start;
        transform: scale(1.2) translate(0px, -45px);
        transition: transform 0.3s linear;
      }
      .slick-list {
        padding: 75px 0px 30px 0 !important;
      }
    `};
    
  @media screen and (min-width: 561px) {
    display: ${({ mobile }) => (mobile ? "none" : "block")};
  }
  @media screen and (max-width: 560px) {
    display: ${({ pc }) => (pc ? "none" : "block")};
  }
  @media screen and (max-width: 1024px) {
    width: 70vw;
  }
  @media screen and (max-width: 560px) {
    width: 100vw;
    .slick-list {
      padding: 30px 0px 30px 0px !important;
    }
    img {
    }
    .slick-slide {
      transform: scale(1) translate(100px, 0px);
      transition: transform 0.3s linear;
    }
    .slick-center {
      align-self: flex-start;
      transform: scale(1) translate(100px, 0px);
      transition: transform 0.3s linear;
    }
  }
`;
